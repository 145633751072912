<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addwallet">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="walletlist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="wallet_url"
        :label="$t('qb.qbdz')"
      ></el-table-column>
      <!-- <el-table-column prop="wallet_image" :label="$t('qb.qbdzewm')">
        <template slot-scope="{ row }"
          ><img :src="row.wallet_image" height="120px"
        /></template>
      </el-table-column> -->
      <el-table-column
        prop="wallet_type"
        :label="$t('qb.qblx')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加钱包分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="190px"
        ref="addref"
        :rules="addrules"
      >
        <el-form-item :label="$t('qb.qbdz')" prop="walleturl">
          <el-input v-model="addform.walleturl"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('qb.qbdzewm')" prop="walletimage">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img
              v-if="addform.walletimage"
              :src="addform.walletimage"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item :label="$t('qb.qblx')" prop="wallettypeid">
          <el-select v-model="addform.wallettypeid" :placeholder="$t('qxz')">
            <el-option
              v-for="item in wallettype"
              :key="item.id"
              :label="item.wallet_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('qb.cplx')" prop="productid">
          <el-select :placeholder="$t('qxz')" v-model="addform.productid">
            <el-option
              v-for="item in product"
              :key="item.id"
              :label="item.product_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('qb.yonghu')" prop="userid">
          <el-select v-model="addform.userid" multiple :placeholder="$t('qxz')">
            <el-option
              v-for="item in ids"
              :key="item.id"
              :label="item.id"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑钱包分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="190px"
        ref="editref"
        :rules="addrules"
      >
        <el-form-item :label="$t('qb.qbdz')" prop="walleturl">
          <el-input v-model="addform.walleturl"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('qb.qbdzewm')" prop="walletimage">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload"
          >
            <img
              v-if="addform.walletimage"
              :src="addform.walletimage"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item :label="$t('qb.qblx')" prop="wallettypeid">
          <el-select v-model="addform.wallettypeid" :placeholder="$t('qxz')">
            <el-option
              v-for="item in wallettype"
              :key="item.id"
              :label="item.wallet_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('qb.cplx')" prop="productid">
          <el-select :placeholder="$t('qxz')" v-model="addform.productid">
            <el-option
              v-for="item in product"
              :key="item.id"
              :label="item.product_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('qb.yonghu')" prop="userid">
          <el-select v-model="addform.userid" multiple :placeholder="$t('qxz')">
            <el-option
              v-for="item in ids"
              :key="item.id"
              :label="item.id"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      walletlist: [],
      total: 0,
      loading: true,
      // 添加/编辑钱包数据对象
      addform: {
        walleturl: '',
        wallettypeid: '',
        walletimage: '',
        productid: '',
        userid: []
      },
      // 编辑的id
      editid: '',
      // 产品对象
      product: [],
      wallettype: [],
      // 用户id
      ids: [],
      dialogvisible: false,
      addrules: {
        walleturl: [
          { required: true, message: '请填写钱包地址', trigger: 'blur' }
        ],
        walletimage: [
          { required: true, message: '请上传二维码', trigger: 'blur' }
        ],
        productid: [
          { required: true, message: '请选择产品类型', trigger: 'blur' }
        ],
        wallettypeid: [
          { required: true, message: '请选择钱包类型', trigger: 'blur' }
        ]
      },
      editdialogvisible: false,
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/wallet/walletlist', {
        params: this.queryinfo
      })
      this.walletlist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    async addwallet() {
      const { data } = await this.$http.get('/admin/wallet/addwallet')
      if (data) {
        if (data.code === 200) {
          this.wallettype = data.data.wallettype
          this.product = data.data.product
          this.ids = data.data.ids
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.dialogvisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.addref.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 提交添加分类
    submit() {
      this.$refs.addref.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/wallet/addwallet',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/wallet/editwallet/id/' + id)
      this.addform.walleturl = data.data.wallet.wallet_url
      this.addform.walletimage = data.data.wallet.wallet_image
      this.addform.productid = data.data.wallet.product_type_id
      this.addform.wallettypeid = data.data.wallet.wallet_type_id
      this.addform.userid = data.data.wallet.users
      this.editid = data.data.wallet.id
      this.product = data.data.product
      this.wallettype = data.data.wallettype
      this.ids = data.data.ids
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editref.validate(async valid => {
        if (!valid) return false
        this.addform.id = this.editid
        const { data } = await this.$http.post(
          '/admin/wallet/editwallet',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.editclose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editref.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('quding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      })
        .then(async () => {
          const { data } = await this.$http.post('/admin/wallet/delwallet', {
            id
          })
          // console.log(data)
          if (data) {
            if (data.code === 200) {
              this.$message.success(this.getlang(data.msg))
              this.getlist()
            } else {
              this.$message.error(this.getlang(data.msg))
            }
          }
        })
        .catch(() => {
          // this.$message.info('已取消删除')
        })
    },
    // 图片上传成功操作
    upload(res) {
      this.addform.walletimage = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
